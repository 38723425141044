.body {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

canvas {
  border: 5px solid gray;
  background-color: black;
  width: 60vw;
  height: 50vw;
  max-height: 80%;
  max-width: 100%;
  /* min-width: 600px;
  min-height: 800px; */
}

/* @media (max-width: 1000px) {
  canvas {
    max-height: 800px;
  }
}

@media (max-width: 1400px) {
  canvas {
    max-height: 1200px;
  }
}
@media (max-width: 1200px) {
  canvas {
    max-height: 1000px;
  }
} */

@media (max-width: 600px) {
  canvas {
    display: none;
  }
}

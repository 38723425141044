* {
  box-sizing: border-box;
}
.about {
  width: 100%;
  height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.about_photo {
  width: 35%;
  height: auto;
  border-radius: 3px;
  border-style: ridge;
  color: white;
  margin: 10px;
  position: relative;
  margin-top: 50px;
}
.about_technical {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-around;
  align-items: center;
}

.title {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 64px;
  text-align: center;
  padding-top: 20px;
}
.about_technical_content {
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 4px;
}
.about_technical ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
}
.about_technical p {
  max-width: 40%;

  border-radius: 3px;
  border-style: ridge;
  color: white;
  padding: 10px;
  box-shadow: 0px 0px 7px 2px white;
  position: relative;
}
/* 
    ......600px|
    -----
    600px
    .
    .
    800px
    .
    .
    -----
*/
@media (max-width: 600px) {
  .about_technical p {
    font-size: 16px;
    max-width: 80%;
    text-align: justify;
  }
  .about_technical_content {
    flex-direction: column;
  }

  .about_photo {
    width: 80%;
    height: auto;
  }

  .title {
    font-size: 48px;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 36px;
  }
}

/* 
    .
    .
    .
    .
    600px
    -----
*/
/* @media (max-height: 600px) {
    .about_technical_content {
        transform: translateY(15%);
    }
} */

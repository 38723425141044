.toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background: #343434;
    z-index: 1;
}

.toolbar_logo {
    color: #ffffff;
    text-decoration: none;
    font-size: 1.5rem;
    padding-right: 1.5rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0.5rem;
}
.toolbar_logo a {
    color: #ffffff;
    text-decoration: none;
}

.toolbar_navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
}

.spacer {
    flex: 1;
}

.toolbar_navigtaion-items a {
    color: #ffffff;
    text-decoration: none;
}
.toolbar_navigtaion-items ul {
    list-style: none;
    display: flex;
    padding: 0;
    font-size: 1.1rem;
}

.toolbar_navigtaion-items li {
    padding: 0 0.5rem;
}
/* Remove list item if screen width falls below 600px (inclusive) */
@media (max-width: 600px) {
    .toolbar_navigtaion-items {
        display: none;
    }
}
/* Remove side bar if screen width goes above 600px(exclusive)*/
@media (min-width: 600px) {
    .toolbar_sidebar-toggler {
        display: none;
    }
    .toolbar_logo {
        padding-left: 0;
    }
}

html,
body,
#app {
    height: 100%;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.app-body {
    height: 100%;
    width: 100%;
    background-color: #231e20;
}
.main {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;
}

.footer {
  width: 100%;
  height: 4vh;
  background-color: #323232;
  display: flex;
  position: static;
  justify-content: center;
  align-items: flex-end;
  margin: 0;
  box-shadow: 5px 5px 15px 6px white;
}

.footer-text {
  color: white;
}

.footer-text a img {
  border-radius: 50%;
  padding: 5px;
}
.footer img {
  width: 40px;
}

svg {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}

.image-card {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 16px;
}

.description {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    text-align: center;
    color: white;
}

.side-bar {
    background: #343434;
    height: 100%;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    max-width: 300px;
    z-index: 200;
    transform: translateX(-100%);
    transition: 0.4s ease-out;
}

.side-bar.open {
    transform: translateX(0);
}

.side-bar ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
}

.side-bar li {
    margin: 0.5rem 0;
}

.side-bar a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 36px;
    margin: 8px;
}

.side-bar a:hover,
.side-bar a:active {
    color: white;
    box-shadow: 4px 4px 12px 6px #1a1515;
}
.side-bar a:hover {
    font-size: 36px;
}

@media (min-width: 600px) {
    .side-bar {
        display: none;
    }
}

.project-page {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sliding-block {
    opacity: 0;
    transform: translateY(50%);
    transition: all 0.7s ease-in;
}

.fade-block {
    opacity: 0;
    transition: all 0.3s ease-in;
}

.fade-block.show {
    opacity: 1;
}
.fade-block.hide {
    opacity: 0;
}

.sliding-block.show {
    transform: translateY(0);
    opacity: 1;
}
.sliding-block.hide {
    opacity: 0;
    transform: translateY(-50%);
}

.title {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 64px;
    text-align: center;
    color: white;
    padding-top: 20px;
}

.container {
    max-width: 60%;
}
.game {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24px;
    text-align: center;
    color: white;
    padding-top: 20px;
}
.game.available {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    padding-bottom: 60px;
}
.game.unavailable {
    display: none;
    width: 70%;
    padding-bottom: 30px;
}

@media (max-width: 800px) {
    .container {
        max-width: 90%;
    }
}

@media (max-width: 600px) {
    .title {
        font-size: 48px;
    }
    .game {
        height: 200px;
    }
    .game.available {
        display: none;
    }
    .game.unavailable {
        display: flex;
    }
}

@media (max-width: 300px) {
    .title {
        font-size: 36px;
    }
}

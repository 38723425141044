.content-block {
    width: 100%;
    display: flex;
    padding: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex: 1;
    /* border-radius: 0;
    border-style: ridge;
    padding: 10px;
    box-shadow: 0px 0px 7px 2px white; */
}

.content-block {
    padding-left: 80px;
}

@media (max-width: 1300px) {
    .content-block {
        padding-left: 30px;
    }
}

.content-text-image {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: white;
    max-width: 70%;
    display: flex;
    flex: 1;
}

@media (max-width: 600px) {
    .content-text-image {
        font-size: 12px;
    }
}

.content-notext {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    text-align: center;
    color: white;
    max-width: 70%;
    display: flex;
    flex: 1;
}
@media (max-width: 1000px) {
    .content-block {
        flex-direction: column;
    }
    .content-notext {
        max-width: 100%;
    }
    .content-text-image {
        max-width: 100%;
    }
}

.place-holder {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 10px;
    padding: 2px 2px;
    border-style: groove;
    border-radius: 4px;
    border-color: white;
    border-width: 2px;
}

.text-block {
    max-width: 40%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-block p {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border-style: ridge;
    color: white;
    font-size: 20px;
    padding: 10px;
    box-shadow: 0px 0px 7px 2px white;
}

@media (max-width: 800px) {
    .text-block {
        max-width: 70%;
    }
}

@media (max-width: 600px) {
    .text-block p {
        font-size: 16px;
    }
}

@media (max-width: 300px) {
    .text-block p {
        font-size: 12px;
    }
}

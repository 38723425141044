* {
  box-sizing: border-box;
}

.project-body {
  height: 100%;
  width: 100%;
  min-height: 96vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.project-title {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 64px;
  text-align: center;
  color: white;
  padding-top: 20px;
}

.project-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  flex-direction: column;
}

.project-content ul {
  flex-wrap: wrap;
  display: flex;
  list-style: none;
}
.project-content ul li {
  flex: 1 0 25%;
}

@media (max-width: 500px) {
  .project-content {
    padding: 0;
    margin: 0;
  }
  .project-content ul {
    padding: 0;
    margin: 0;
  }

  .project-title {
    font-size: 46px;
  }
}

@media (max-width: 250px) {
  .project-title {
    font-size: 38px;
  }
}

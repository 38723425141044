* {
  box-sizing: border-box;
}

.home {
  color: #000000;
  text-decoration: none;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 96vh;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  border-radius: 3px;
  border-color: white;
}

.body-block {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  /* border-radius: 3px;
    border-style: ridge; */
  margin-left: 5px;
  margin-right: 5px;
  justify-content: center;
  align-items: center;
  font-family: serif;
  background: none;
  color: white;
  z-index: 0;
}
.background-lightbulb {
  z-index: -1;
  position: absolute;
  width: 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.body-block_left {
  box-shadow: 5px 5px 15px 6px #fddd68;
  display: flex;
  width: 30%;
  height: 60%;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
  position: relative;
  font-weight: 800;
  top: -80px;
  left: -160px;
  border-radius: 3px;
  border-style: ridge;
  color: white;
  animation: light_animation_left 6s infinite;
  transition: all 0.4s;
}
@keyframes light_animation_left {
  50% {
    box-shadow: 2px 2px 15px 1px #fddd68;
  }
}
@keyframes light_animation_right {
  50% {
    box-shadow: -2px -2px 15px 1px #fddd68;
  }
}

.body-block_right {
  box-shadow: -5px -5px 15px 6px #fddd68;
  display: flex;
  width: 30%;
  height: 60%;
  justify-content: center;
  align-items: center;
  font-size: x-large;
  position: relative;
  top: 80px;
  left: 160px;
  text-align: end;
  font-weight: 800;
  border-radius: 3px;
  border-style: ridge;
  color: white;
  animation: light_animation_right 6s infinite;
  transition: all 0.4s;
}

/* Remove list item if screen width falls below 780px (inclusive) */
@media (max-width: 800px) {
  .body-block_right {
    left: 0px;
    width: 50%;
    top: 200px;
    text-align: center;
  }
  .body-block_left {
    left: 0px;
    top: -150px;
    width: 40%;
    font-size: 26px;
    text-align: center;
  }
  .background-lightbulb {
    width: 20%;
    height: auto;
    top: 220px;
  }
}

@media (max-width: 400px) {
  .body-block_right {
    left: 0px;
    width: 50%;
    top: 100px;
    text-align: center;
  }
}

@media (max-width: 400px) and (max-height: 850px) {
  .body-block_left {
    left: 0px;
    top: -200px;
    text-align: center;
  }
}

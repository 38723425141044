.image-block {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.image-block img {
    width: 350px;
    height: 200px;
    object-fit: cover;
    margin: 10px;
    padding: 2px 2px;

    border-style: groove;
    border-radius: 4px;
    border-color: white;
    border-width: 2px;
}

@media (max-width: 400px) {
    .image-block img {
        padding: 0;
        width: 300px;
        height: auto;
        margin: 0;
    }
}

@media (max-width: 300px) {
    .image-block img {
        padding: 0;
        width: 250px;
        height: auto;
        margin: 0;
    }
}

@media (max-width: 250px) {
    .image-block img {
        width: 220px;
    }
}
